<template>
    <div class="reporting">
        <div style="display: flex;">
          <Headbuttom></Headbuttom>
           <el-button type="primary" style="margin-left:20px" @click="onPlus" size="small">
             <i class="el-icon-plus" />新增
            </el-button>
          <div @click="handFromshow"><el-input
          size="small"
          class="dev_dowm"
          placeholder=""
          suffix-icon="el-icon-arrow-down"
          :readonly="true"
          >
        </el-input>
        </div>
       
        </div>

        <div class="attheadfrom" v-if="flag">
        <el-form
            :inline="true"
            size="small"
            :model="searchParams"
            class="demo-form-inline"
        >
            <el-form-item label="设备名称">
                <el-select v-model="searchParams.device_id" placeholder="请选择">
                    <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="设备序列号">
                <el-input v-model="searchParams.serial_number"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="onSubmit">{{$t("devtable.cx")}}</el-button>
            </el-form-item>

        </el-form>
        </div>

        <Tables @handlerow="handlerow"
        @handlpages="handlpages"
        @handleSizeChange="handleSizeChange"
        :tableLabel="tableLabel"
        :tableData="tableData"
        :flags="flags"
        :operation="operation"
        :configs="configs"></Tables>

        <parkdolg ref="park" :options="options"></parkdolg>
    </div>
</template>
<script>
import Tables from '@/components/table'
import parkdolg from './commpoents/parkdolg'
export default {
     components:{
        parkdolg,
        Tables,
   
    },
    data(){
        return{
            flag:false,
            searchParams:{
                serial_number:'',
                device_id:''
            },
            options: [],
            tableLabel:[
            
            {
              prop: "name",
              label: '设备名称',
              align: "center",
            },
            {
              prop: "f_serial_number",
              label: '设备序列号',
              align: "center",
            },
            {
              prop: "camera_one",
              label: '摄像头1管理车位数',
              align: "center",
            },
            {
              prop: "camera_two",
              label: '摄像头2管理车牌数',
              align: "center",
            },
            
            {
              prop: "route_one",
              label: '路由1MAC+SSID',
              align: "center",
            },
            {
              prop: "route_two",
              label: '路由2MAC+SSID',
              align: "center",
            },
            
            ],
            tableData:[],
            operation:{
              width:150,
              data:[
                {
                  name: "修改",
                  type: "success",
                },
                
                
              ]
            },
            // 分页数据
            configs: {
              page_index:1,
              pagesize: 10,
              total: 20,
              loading: false, // 加载状态
            },
            flags: {
              show: false, // 多选是否显示
              ishow: true, // 序号是否显示
            },
            UserInfo:JSON.parse(window.localStorage.getItem('user')),
        }
    },
    mounted(){
        this.hanequlist()
        this.getpark()
    },
    methods:{
        // 获取设备
        hanequlist(){
            let data={
                method:'co.device.dropdown.list',
                agent_id:this.UserInfo.agent_id
            }
            this.$serve(data).then(res=>{
                if(res.data.code==0){
                    this.options = res.data.data
                    console.log(this.options)
                }
            })
        },
        // 车位设备列表
        getpark(){
          let data ={
              method:'co.parking.device.list',
              page_index:1,
              page_size:10,
              agent_id:this.UserInfo.agent_id,
              device_id:this.searchParams.device_id,
              serial_number:this.searchParams.serial_number
          }
          this.$serve(data).then(res=>{
              if(res.data.code==0){
                this.tableData = res.data.data
                this.configs.total = res.data.count
              }
          })
        },
        onPlus(){
            this.$refs.park.dialogVisible = true
        },
        handFromshow(){
            this.flag = !this.flag
        },
        onSubmit(){
          this.getpark()
        },
        handlerow(item){
          this.$refs.park.dialogVisible = true
          this.$refs.park.getonepack(item.id)
        },
        handlpages(){

        },
        handleSizeChange(){
          
        }
    }
}
</script>
<style scoped>
.reporting{
    padding: 20px;
    background-color: #fff;
}
.dev_dowm{
    margin-left: 30px;
}
.attheadfrom {
    padding: 20px;
    margin-top: 10px;
  }
</style>